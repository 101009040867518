:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper {
  padding: 20px;
}

.swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* new styles added */
.swiper-button {
  position: absolute;
  top: 35%;
  z-index: 10;
  cursor: pointer;
}

@media (max-width: 768px) {
  .swiper-button {
    position: relative;
    cursor: pointer;
  }
}

.image-swiper-button-next {
  right: 50px;
}

.swiper-button-disabled {
  opacity: 0;
  cursor: none;
}

.swiper-pagination-bullet-active {
  width: 50px !important;
  height: 6px !important;
  border-radius: 7px !important;
  background-color: #D9D9D9 !important;
}

/* TODO: working ? */
.markdown, u {
  -webkit-text-decoration: revert;
  text-decoration: revert;
}

@font-face {
font-family: '__GTMaru_cedca5';
src: url(/_next/static/media/0f18569b58abe56c.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__GTMaru_cedca5';
src: url(/_next/static/media/bbcc08fcb1231e89.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: oblique;
}

@font-face {
font-family: '__GTMaru_cedca5';
src: url(/_next/static/media/831cc37fc688b99c.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: oblique;
}

@font-face {
font-family: '__GTMaru_cedca5';
src: url(/_next/static/media/f5eb9520abdd847d.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__GTMaru_Fallback_cedca5';src: local("Arial");ascent-override: 74.99%;descent-override: 20.43%;line-gap-override: 0.00%;size-adjust: 126.65%
}.__className_cedca5 {font-family: '__GTMaru_cedca5', '__GTMaru_Fallback_cedca5'
}

@font-face {
font-family: '__GTAlpina_ff610a';
src: url(/_next/static/media/c01d5733fa8f018f.p.woff2) format('woff2');
font-display: optional;
font-style: normal;
}

@font-face {
font-family: '__GTAlpina_ff610a';
src: url(/_next/static/media/0977146d60541700.p.woff2) format('woff2');
font-display: optional;
font-style: italic;
}@font-face {font-family: '__GTAlpina_Fallback_ff610a';src: local("Arial");ascent-override: 91.04%;descent-override: 24.62%;line-gap-override: 0.00%;size-adjust: 98.31%
}.__className_ff610a {font-family: '__GTAlpina_ff610a', '__GTAlpina_Fallback_ff610a'
}

